require('jquery-ui-dist/jquery-ui');
let move = {

    TableRow : () => {

        if (J('.table-grip-move').length) {

            J( "tbody" ).sortable({
                handle: ".table-grip-move",
                update: function( event, ui ) {
                    J(this).children().each(function(index) {
                        let position = index + 1;
                        J(this).attr('position', position).removeAttr('class style');
                        move.TablePositionRow({'service_id' : J(this).attr('service-id'), 'position' : position}, function(result){

                        });
                    });
                },
                stop: function( event, ui ) {
                    
                }
            }).disableSelection();
        }
    },

    TablePositionRow : (data, callback) => {

        let Ajax = J.ajax({
            url : '/admin/quotations/ajax/table/row/update/position',
            method: 'POST',
            data: data,
            dataType: 'JSON'
        });

        Ajax.done(function(result){
            return callback(result);
        });

        Ajax.fail(function(error){
            return callback(error);
        });
    },

    DashboardWidgetAddPosition : () => {

        if (J('.dashboard-col').length) {

            let dashboard_col_nb = J('.dashboard-col').length;
            let dashboard_col_count = 0;

            let widget_array = {};
                widget_array.widget_positions = [];

            J('.dashboard-col').each(function(col_index, col_item){

                let col_number = J(col_item).attr('col-index');

                //widget_array.widget_positions[col_index] = [];

                if (J(col_item).children('.dashboard-row').length) {
                    J(col_item).children('.dashboard-row').each(function(row_index, row_item){

                        let row_name = J(row_item).attr('data-name');
                        J(row_item).attr({
                            'position': row_index,
                            'col' : col_number
                        });

                        widget_array.widget_positions.push({
                            'name' : row_name,
                            'col' : col_number,
                            'position' : row_index
                        })
                    });
                }
                dashboard_col_count++;

                if (dashboard_col_count === dashboard_col_nb) {
                    console.log(widget_array);
                    move.Ajax('/admin/ajax/dashboard/widget/position', widget_array, function(result){

                        console.log(result);
                    })
                }
            });
        }
    },

    DashboardWidget : () => {

        J( ".dashboard-col" ).sortable({
            connectWith: ".dashboard-col",
            handle: ".fa-arrows-alt",
            stop: function( event, ui ) {
                move.DashboardWidgetAddPosition();
            }
        }).disableSelection();

        move.DashboardWidgetAddPosition();
    },

    Ajax : (url, data = {}, callback) => {

        let Ajax = J.ajax({
            url : url,
            method: 'POST',
            dataType : 'JSON',
            data: data
        });
        Ajax.done(function(result){
            return callback(result);
        });
        Ajax.fail(function(error){
            return callback(error);
        });
    }
}
module.exports = move;
require('jquery-ui-dist/jquery-ui');
let todo = {

    Init : () => {

        J( ".todo-col" ).sortable({
            connectWith: ".todo-col",
            handle: ".fa-arrows-alt",
            items : '.todo-item',
            stop: function( event, ui ) {

                let currentItemId = J(ui.item).attr('todo-id')

                todo.TodoPositionItem('.todo-item', currentItemId);
            }
        }).disableSelection();

        todo.TodoPositionItem('.todo-item');
        todo.Actions();
    },

    Actions : () => {

        Jd.off('click', '.todo-edit-btn').on('click', '.todo-edit-btn', function(e){
            e.preventDefault();
            let id = J(this).attr('data-id');
            J('.todo-edit').hide();
            J('.todo-edit[data-id="'+id+'"]').show();
        });

        Jd.off('click', '.todo-edit-close').on('click', '.todo-edit-close', function(e){
            e.preventDefault();
            J('.todo-edit').hide();
        });
    },

    TodoPositionItem : (children, currentItemId=null) => {

        J(children).each(function(index, item){
            J(item).attr('position', index);

            let id  = J(item).attr('todo-id');
            let col = J(item).parent('.todo-col').attr('col');

            todo.TodoUpdatePosition({
                position: index,
                id: id,
                col : col,
                current_item_id : currentItemId

            }, function(result){
                if (result && result.response === 'success') {
                    console.log(result)
                    J('.todo-edit[data-id="'+id+'"]').find('input[name="col"]').val(col);
                    J('.todo-edit[data-id="'+id+'"]').find('input[name="position"]').val(index);
                }
            })
        });
    },

    TodoUpdatePosition : (data, callback) => {

        let Ajax = J.ajax({
            url : '/admin/todo/ajax/todo/update/position',
            method: 'POST',
            data: data,
            dataType: 'JSON'
        });

        Ajax.done(function(result){
            return callback(result);
        });

        Ajax.fail(function(error){
            return callback(error);
        })
    }

}
module.exports = todo;
let mileage = {

    Init : () => {
        mileage.OnLoad();
        mileage.Actions();
    },

    OnLoad : () => {
        mileage.TableNotice();
    }, 

    TableNoticePower : () => {

    },

    TableNotice : () => {

        if (
            J('#vehicle_type_car_power_container').length && 
            J('#vehicle_type_bike_power_container').length && 
            J('#vehicle_type').length
        ) {

            J('#vehicle_type_car_power_container').hide();
            J('#vehicle_type_bike_power_container').hide();

            let vehicle_type = J('#vehicle_type').val();

            if (vehicle_type === 'car') {
                J('#vehicle_type_car_power_container').show();
                J('#vehicle_type_bike_power_container').hide();
            }
            if (vehicle_type === 'bike') {
                J('#vehicle_type_bike_power_container').show();
                J('#vehicle_type_car_power_container').hide();
            }
        }

        if (
            J('#vehicle_type_car_table_notice').length && 
            J('#vehicle_type_bike_table_notice').length && 
            J('#vehicle_type').length
        ) {

            J('#vehicle_type_car_table_notice').hide();
            J('#vehicle_type_bike_table_notice').hide();

            let vehicle_type = J('#vehicle_type').val();

            if (vehicle_type === 'car') {

                J('#vehicle_type_car_table_notice').show();
                J('#vehicle_type_bike_table_notice').hide();

                if (J('select[name="vehicle_type_car_power"]').length) {

                    let vehicle_power = J('select[name="vehicle_type_car_power"]').val();
    
                    if (J('tr.car-power-row').length) {
    
                        J('tr.car-power-row').removeClass('car-power-row-active');
    
                        if (J('tr.car-power-row[data-power="'+vehicle_power+'"]').length) {
                            J('tr.car-power-row[data-power="'+vehicle_power+'"]').addClass('car-power-row-active');
                        }
                    }
                }

                if (J('input[name="vehicle_power"]').length) {

                    let vehicle_power = J('input[name="vehicle_power"]').val();
    
                    if (J('tr.car-power-row').length) {
    
                        J('tr.car-power-row').removeClass('car-power-row-active');
    
                        if (J('tr.car-power-row[data-power="'+vehicle_power+'"]').length) {
                            J('tr.car-power-row[data-power="'+vehicle_power+'"]').addClass('car-power-row-active');
                        }
                    }
                }
            }
            if (vehicle_type === 'bike') {

                J('#vehicle_type_bike_table_notice').show();
                J('#vehicle_type_car_table_notice').hide();

                if (J('select[name="vehicle_type_bike_power"]').length) {

                    let vehicle_power = J('select[name="vehicle_type_bike_power"]').val();
    
                    if (J('tr.bike-power-row').length) {
    
                        J('tr.bike-power-row').removeClass('bike-power-row-active');
    
                        if (J('tr.bike-power-row[data-power="'+vehicle_power+'"]').length) {
                            J('tr.bike-power-row[data-power="'+vehicle_power+'"]').addClass('bike-power-row-active');
                        }
                    }
                }

                if (J('input[name="vehicle_power"]').length) {

                    let vehicle_power = J('input[name="vehicle_power"]').val();
    
                    if (J('tr.bike-power-row').length) {
    
                        J('tr.bike-power-row').removeClass('bike-power-row-active');
    
                        if (J('tr.bike-power-row[data-power="'+vehicle_power+'"]').length) {
                            J('tr.bike-power-row[data-power="'+vehicle_power+'"]').addClass('bike-power-row-active');
                        }
                    }
                }
            }
        }
    },

    Actions : () => {

        Jd.off('change', '#vehicle_type').on('change', '#vehicle_type', function(){
            mileage.TableNotice();
        });

        Jd.off('change', '#vehicle_type_car_power').on('change', '#vehicle_type_car_power', function(){
            mileage.TableNotice();
        });

        Jd.off('change', '#vehicle_type_bike_power').on('change', '#vehicle_type_bike_power', function(){
            mileage.TableNotice();
        });
    }
}
module.exports = mileage;
let scrollTo = {

    Anchor : (btnClass) => {

        Jd.off('click', btnClass).on('click', btnClass, function(){
            let anchor = J(this).attr('data-anchor');
            scrollTo.GoToAnchor(anchor);
        });
    },

    GoToAnchor : (anchor) => {
        J('html,body').animate({scrollTop: (J(anchor).offset().top - 50)},'slow');
    }
}
module.exports = scrollTo;
let calendar = {

    Init : () => {

        let weather = require('./weather.js');

        weather.AjaxCall(function(result){
            if (result && result.response === 'success' && result.data) {

                console.log(result);

                if (result.data.list && result.data.list.length) {

                    J.each(result.data.list, function(index, item){

                        let dttxt = item.dt_txt;
                            date = dttxt.split(' ')[0];
                        let time = dttxt.split(' ')[1];
                            time = parseInt(time.replaceAll(':', ''));

                        let icon = item.weather[0] && item.weather[0].icon ? item.weather[0].icon : null;

                        if (icon && J('.js-cal-day[event-date="'+date+'"]').length && !J('.js-cal-day-weather[date="'+date+'"]').length && time <= 150000) {

                            J('.js-cal-day[event-date="'+date+'"]').append(`
                                <div class="js-cal-day-weather" date="${date}">
                                    <img src="https://openweathermap.org/img/wn/${icon}.png" />
                                </div>
                            `)
                        }
                    });
                }

            }
        })

    }
}
module.exports = calendar;
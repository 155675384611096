let nav = {

    Init : () => {
        nav.View();
        nav.NavActions();
    },

    NavActions : () => {
        Jd.off('click', '.nav-actions').on('click', '.nav-actions', function(event){
            event.preventDefault();

            if (J('body').hasClass('nav-closed')) {
                J('body').removeClass('nav-closed');
                nav.NavActionsAjax({'NAV_VIEW' : 'open'}, function(result){
                    console.log(result)
                });
            } else {
                J('body').addClass('nav-closed');
                nav.NavActionsAjax({'NAV_VIEW' : 'closed'}, function(result){
                    console.log(result)
                });
            }
            return;
        });

        Jd.off('click', '.js-mobile-menu').on('click', '.js-mobile-menu', function(event){
            if (J('.nav').is(':visible')) {
                J('.nav').hide();
            } else {
                J('.nav').show();
            }
        });
    },

    NavActionsAjax : (data, callback) => {

        let Ajax = J.ajax({
            url : '/admin/params/navview',
            method: 'POST',
            data : JSON.stringify(data),
            dataType: 'JSON'
        });

        Ajax.done(function(result){
            return callback(result);
        });

        Ajax.fail(function(error){
            return callback(result);
        });
    },

    View : () => {

        let pathname = window.location.pathname;
        
        J('.nav > ul > li > a').each(function(index, item){

            let href = J(item).attr('href');
            if (pathname != '/admin/') {
                href = href.replace('/admin/','');
            }

            if (href.length > 1 && pathname.indexOf(href) != -1) {
                J(item).parent().addClass('nav-current');
            }
        });
    }
}
module.exports = nav;
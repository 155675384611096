let common = {
    Moment : require('moment'),
    Init: ()=> {
        Jd.off('click', '.flash-message-close').on('click', '.flash-message-close', function(){
            J(this).parent('.flash-message').remove();
        });

        if (J('#header_datetime').length) {

            setInterval(function(){
                J('#header_datetime').text( common.Moment().locale("fr").format('LLLL') );
            },1000);
        }
    }
}
module.exports = common;
let apiGouv = {

    Init : () => {

        Jd.off('submit', '#society_search_api_gouv_form').on('submit', '#society_search_api_gouv_form', function(e){
            e.preventDefault();

            J('#society_search_api_gouv_result').html('Recherche en cours...');
            let search = J('#society_search_api_gouv').val();
            if (search.length) {
                apiGouv.Search(search, 1, 5, function(datas){
                    console.log(datas);
                    apiGouv.AddDAtas(datas);
                });
            }
        });

        Jd.off('click', '.society-search-api-gouv-result-paginate-item').on('click', '.society-search-api-gouv-result-paginate-item', function(){

            J('#society_search_api_gouv_result').html('Recherche en cours...');

            let page = parseInt(J(this).attr('data-page'));
            let search = J('#society_search_api_gouv').val();
            if (search.length) {
                apiGouv.Search(search, page, 5, function(datas){
                    apiGouv.AddDAtas(datas);
                });
            }
        });

        Jd.off('click', '.society-search-api-gouv-result-item').on('click', '.society-search-api-gouv-result-item', function(){

            let name = J(this).children('.api-gouv-result-item[data-type="nom_complet"]').attr('data-value');
            let siret = J(this).children('.api-gouv-result-item[data-type="siret"]').attr('data-value');
            let siren = J(this).children('.api-gouv-result-item[data-type="siren"]').attr('data-value');
            let postcode = J(this).children('.api-gouv-result-item[data-type="code_postal"]').attr('data-value');
            let adresse_complete = J(this).children('.api-gouv-result-item[data-type="adresse_complete"]').attr('data-value');
            let address1 = adresse_complete.split(postcode)[0];
            let city = J(this).children('.api-gouv-result-item[data-type="libelle_commune"]').attr('data-value');
            let lat = J(this).children('.api-gouv-result-item[data-type="latitude"]').attr('data-value');
            let lng = J(this).children('.api-gouv-result-item[data-type="longitude"]').attr('data-value');

            if (J('#society_add').length) {

                if (J('input[name="name"]').length) {
                    J('input[name="name"]').val(name);
                }
                if (J('input[name="siret"]').length) {
                    J('input[name="siret"]').val(siret);
                }
                if (J('input[name="siren"]').length) {
                    J('input[name="siren"]').val(siren);
                }
                if (J('input[name="address1"]').length) {
                    J('input[name="address1"]').val(address1);
                }
                if (J('input[name="postcode"]').length) {
                    J('input[name="postcode"]').val(postcode);
                }
                if (J('input[name="city"]').length) {
                    J('input[name="city"]').val(city);
                }
                if (J('input[name="lat"]').length) {
                    J('input[name="lat"]').val(lat);
                }
                if (J('input[name="lng"]').length) {
                    J('input[name="lng"]').val(lng);
                }
                if (J('input[name="country"]').length) {
                    J('input[name="country"]').val('France');
                }
            }
            if (J('#sub_contractor_add').length) {

                if (J('input[name="society_name"]').length) {
                    J('input[name="society_name"]').val(name);
                }
                if (J('input[name="siret"]').length) {
                    J('input[name="siret"]').val(siret);
                }
                if (J('input[name="siret"]').length) {
                    J('input[name="siret"]').val(siret);
                }
                if (J('input[name="siren"]').length) {
                    J('input[name="siren"]').val(siren);
                }
                if (J('input[name="address1"]').length) {
                    J('input[name="address1"]').val(address1);
                }
                if (J('input[name="postcode"]').length) {
                    J('input[name="postcode"]').val(postcode);
                }
                if (J('input[name="city"]').length) {
                    J('input[name="city"]').val(city);
                }
                if (J('input[name="lat"]').length) {
                    J('input[name="lat"]').val(lat);
                }
                if (J('input[name="lng"]').length) {
                    J('input[name="lng"]').val(lng);
                }
                if (J('input[name="country"]').length) {
                    J('input[name="country"]').val('France');
                }
            }
        });

    },

    AddDAtas : (datas) => {

        J('#society_search_api_gouv_result').html('');

        if (J('#society_search_api_gouv_result_total').length) {
            J('#society_search_api_gouv_result_total').remove();
        }
        if (J('#society_search_api_gouv_result_paginate').length) {
            J('#society_search_api_gouv_result_paginate').remove();
        }
        if (datas && datas.total_results) {
            J('#society_search_api_gouv_result').append('<div id="society_search_api_gouv_result_total">Résultat trouvés : '+datas.total_results+'</div>');
        }
        if (datas && datas.total_pages && datas.total_pages > 1) {
            J('#society_search_api_gouv_result').append('<div id="society_search_api_gouv_result_paginate"></div>');
            for (var i=1; i <= datas.total_pages;i++) {

                let item_class = "society-search-api-gouv-result-paginate-item";
                if (datas.page == i) {
                    item_class="society-search-api-gouv-result-paginate-item society-search-api-gouv-result-paginate-item-active";
                }
                J('#society_search_api_gouv_result_paginate').append('<div data-page="'+i+'" class="'+item_class+'">'+i+'</div>');
            }
        }
        if (datas.results && datas.results.length) {

            J.each(datas.results, function(index, item) {

                let html_dirigeants = '';

                console.log(item.dirigeants);

                if (item.dirigeants && item.dirigeants.length) {

                    html_dirigeants += '<div style="font-weight:bold;padding:5px 0;">DIRIGEANTS :</div>';

                    for (var i=0; i < item.dirigeants.length; i++) {

                        if (item.dirigeants[i].nom && item.dirigeants[i].prenoms) {
                            html_dirigeants += `
                                <div style="display:flex;gap:5px;padding:5px 0;">
                                    <div>${i+1}</div>
                                    <div>-</div>
                                    <div>${item.dirigeants[i].nom}</div>
                                    <div>${item.dirigeants[i].prenoms}</div>
                                    <div>-</div>
                                    <div>${item.dirigeants[i].qualite}</div>
                                </div>
                            `
                            ;
                        } else {
                            html_dirigeants += '<div style="display:flex;gap:5px;padding:5px 0;"><div>'+(i+1)+'</div><div>-</div><div>information manquante</div></div>';
                        }
                    }
                }

                J('#society_search_api_gouv_result').append(`
                    <div class="society-search-api-gouv-result-item">
                        <div class="api-gouv-result-item api-gouv-result-item-name" data-type="nom_complet" data-value="${item.nom_complet}">${item.nom_complet}</div>
                        <div class="api-gouv-result-item" data-type="siren" data-value="${item.siren}">SIREN : ${item.siren}</div>
                        <div class="api-gouv-result-item" data-type="siret" data-value="${item.siege.siret}">SIRET : ${item.siege.siret}</div>
                        <div class="api-gouv-result-item" data-type="adresse_complete" data-value="${item.siege.adresse}">ADRESSE : ${item.siege.adresse}</div>
                        <div class="api-gouv-result-item" data-type="code_postal" data-value="${item.siege.code_postal}">CODE POSTAL : ${item.siege.code_postal}</div>
                        <div class="api-gouv-result-item" data-type="libelle_commune" data-value="${item.siege.libelle_commune}">COMMUNE : ${item.siege.libelle_commune}</div>
                        <div class="api-gouv-result-item" data-type="latitude" data-value="${item.siege.latitude}">LAT : ${item.siege.latitude}</div>
                        <div class="api-gouv-result-item" data-type="longitude" data-value="${item.siege.longitude}">LNG : ${item.siege.longitude}</div>
                        <br />
                        ${html_dirigeants}
                        <div class="api-gouv-result-item-select">Selectionner</div>
                    </div>
                `);

            })
        } else {
            J('#society_search_api_gouv_result').html('Aucun résultat');
        }
    },

    Search : (search, page=1, per_page=25, callback) => {

            let ajax = J.ajax({
                url : '/admin/ajax/apigouv',
                data: {
                    search : search,
                    page : page,
                    per_page : per_page
                },
                method : 'POST',
                dataType: 'JSON'
            })

        ajax.done(function(datas){
            console.log(datas);
            return callback(datas);
        })

    }
}
module.exports = apiGouv;
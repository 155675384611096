const { isXMLDoc } = require("jquery");

let weather = {

    AjaxCall : (callback) => {

        let Ajax = J.ajax({
            url : '/admin/ajax/weather',
            method: 'POST',
            dataType: 'JSON',
            data: {
                'get_weather' : 1
            }
        });

        Ajax.done(function(result){
            return callback(result);
        });

        Ajax.fail(function(error){
            console.log(error)
            return callback(error);
        });
    }
}
module.exports = weather;
require('jquery-ui-dist/jquery-ui');
let table = {
    Init : () => {
        table.Search();

        console.log('table')

        if (J('.table-list-month').length) {

            J( ".table-list-month" ).sortable({
                items: 'tbody > tr',
                handle: ".table-grip-move",
                connectWith: ".table-list-month",
                update: function( event, ui ) {
                },
                stop: function( event, ui ) {

                    let month = J(ui.item).parent().attr('month');
                    let year = J(ui.item).parent().attr('year');

                    J(ui.item).attr('month', month);
                    J(ui.item).attr('year', year);

                    table.LoopListRowTableMonth();
                }
            }).disableSelection();
        }
    },

    LoopListRowTableMonth : () => {

        if (J('.table-list-month-row').length) {

            let rowsList = [];
            let count = 0;
            let rowLength = J('.table-list-month-row').length;

            J('.table-list-month-row').each(function(index, item) {

                let rowType = J(item).attr('row-type');

                let rowId = J(item).attr('row-id');
                let month = J(item).attr('month');
                let year = J(item).attr('year');
                let position = index;

                rowsList.push({
                    row_id : rowId,
                    month : month,
                    year: year,
                    position: position
                });

                count++;

                if (count === rowLength) {
                    table.AjaxPositionMonthRows(rowsList, rowType, function(result){

                        if (result && result.response === 'success') {
                            window.location.reload();
                        }
                    });
                }
            });
        }
    },

    AjaxPositionMonthRows : (data, rowType, callback) => {

        let url = '/admin/commands/ajax/month/row/update/position';
        if (rowType === 'invoice') {
            url = '/admin/invoices/ajax/month/row/update/position';
        }

        let Ajax = J.ajax({
            url : url,
            method: 'POST',
            data: {
                rows_list : data
            },
            dataType: 'JSON'
        });

        Ajax.done(function(result){
            return callback(result);
        });

        Ajax.fail(function(error){
            return callback(error);
        });
    },
    Search : () => {

        /*Jd.off('submit', 'form.table-option-search').on('submit', 'form.table-option-search', function(e){
            e.preventDefault();
            return;
        });*/

        if (J('.table-option-search').length > 0 && J('table > tbody > tr').length > 0) {

            /*Jd.off('keyup', 'input[name="s"]').on('keyup', 'input[name="s"]', function(e){

                let value = (J(this).val()).toUpperCase();
                let $table = J(this).parent().parent().parent().children('table');

                if (value.length > 2) {

                    $table.children('tbody').children('tr').hide();

                    $table.children('tbody').children('tr').each(function(index, item){
                        let trText = (J(item).text()).toUpperCase();
                        if (trText.indexOf(value) != -1) {
                            J(item).show();
                        }
                    });

                } else {
                    $table.children('tbody').children('tr').show();
                }
            });*/
        }
    }
}
module.exports = table;
let tooltip = {

    Init : () => {
        if (J('.as-tooltip').length) {
            Jd.off('mouseenter', '.as-tooltip').on('mouseenter', '.as-tooltip', function( e ){

                if (J('.tooltip')) J('.tooltip').remove();

                if (J(this).attr('data-tooltip') != undefined && J(this).attr('data-tooltip') != '') {

                    J('body').append('<span class="tooltip">'+J(this).attr('data-tooltip')+'</span>');

                    if (!J(this).attr('tooltip-left') && !J(this).attr('tooltip-right')) {
                        J('.tooltip').css({'top' : tooltip.Top( e )+'px', 'left' : tooltip.Left( e )+'px'});
                    } else if (J(this).attr('tooltip-left')) {
                        J('.tooltip').css({'top' : (tooltip.Top( e )-30)+'px', 'left' : (tooltip.Left( e )+parseInt(J(this).attr('tooltip-left')))+'px'});
                    } else if (J(this).attr('tooltip-right')) {
                        J('.tooltip').css({'top' : (tooltip.Top( e )-30)+'px', 'left' : (tooltip.Left( e )-parseInt(J(this).attr('tooltip-right')))+'px'});
                    } 
                }
            });
            Jd.off('mouseleave', '.as-tooltip').on('mouseleave', '.as-tooltip', function( e ){ J('.tooltip').remove(); });
        }
    },
    Top : ( e ) => {
        return e.pageY + 30;
    },
    Left : ( e ) => {
        return e.pageX - (J('.tooltip').outerWidth()/2);
    }
}
module.exports = tooltip;
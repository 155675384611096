import '../scss/app.scss';
"use-strict";

let jQuery  = require('jquery');
window.J    = jQuery;
window.Jd   = J(document);

Jd.ready(function(){
    console.log('*** APP is ready ***')
    let bodyId = J('body').attr('id');

    require('./modules/nav').Init();
    require('./modules/modal').Init();
    require('./modules/tooltip').Init();
    require('./modules/table').Init();
    require('./modules/common').Init();

    if (bodyId === 'map') {
        require('./modules/map').Init('map_main');
    }  else if (bodyId === 'address_edit') {
        require('./modules/map').AddressEdit('address_map');
    } else if (bodyId === 'customer_edit') {
        require('./modules/map').CustomerEdit('customer_map');
        require('./modules/tab').Init();
    } else if (bodyId === 'society_edit') {
        require('./modules/tab').Init();
        require('./modules/map').SocietyEdit('society_map');
    } else if (bodyId === 'society_add') {
        require('./modules/apiGouv').Init();
    } else if (bodyId === 'sub_contractor_add') {
        require('./modules/apiGouv').Init();
    } else if (bodyId === 'sub_contractor_edit') {
        require('./modules/tab').Init();
        require('./modules/map').SubcontractorEdit('subcontractor_map');
    } else if (bodyId === 'quotation_edit') {
        require('./modules/form').Init();
        require('./modules/tab').Init();
        require('./modules/scrollTo').Anchor('.pricelist-anchor-btn');
        require('./modules/move').TableRow();
    } else if (bodyId === 'command_edit') {
        require('./modules/form').Init();
        require('./modules/tab').Init();
    } else if (bodyId === 'invoice_edit') {
        require('./modules/form').Init();
        require('./modules/tab').Init();
    } else if (bodyId === 'service_edit') {
        require('./modules/form').Init();
    } else if (bodyId === 'project_edit') {
        require('./modules/form').Init();
        require('./modules/tab').Init();
        require('./modules/slideshow').Init();
    } else if (bodyId === 'params') {
        require('./modules/form').Init();
        require('./modules/tab').Init();
    } else if (bodyId === 'quotation_email') {
        require('./modules/tab').Init();
    } else if (bodyId === 'calendar') {
        require('./modules/calendar').Init();
    } else if (bodyId === 'dashboard') {
        require('./modules/stat').Init();
        require('./modules/move').DashboardWidget();
    } else if (bodyId === 'expense_edit') {
        require('./modules/form').Init();
        require('./modules/tab').Init();
    } else if (bodyId === 'cost_edit') {
        require('./modules/form').Init();
    } else if (bodyId === 'settlement_edit') {
        require('./modules/form').Init();
        require('./modules/tab').Init();
    } else if (bodyId === 'payment_edit') {
        require('./modules/form').Init();
    } else if (bodyId === 'pricelist') {
        require('./modules/scrollTo').Anchor('.pricelist-anchor-btn');
        require('./modules/priceList').Init();
    } else if (bodyId === 'pricelist_category_remove') {
        require('./modules/tab').Init();
    } else if (bodyId === 'dev') {
        require('./modules/tab').Init();
        require('./modules/todo').Init();
    } else if (bodyId === 'todo') {
        require('./modules/tab').Init();
        require('./modules/todo').Init();
    } else if (bodyId === 'mileagecosts_add') {
        require('./modules/mileage').Init();
    } else if (bodyId === 'mileagecosts_edit') {
        require('./modules/tab').Init();
        require('./modules/form').Init();
        require('./modules/mileage').Init();
    }
});
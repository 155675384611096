let modal = {

    Init : () => {
        modal.Actions();
        modal.Close();
    },

    Actions : () => {

        Jd.off('click', '#how_it_works_btn').on('click', '#how_it_works_btn', function(){
            J('#modal_how_it_works').fadeIn();
            return;
        });

        Jd.off('click', '.modal-form-add-btn').on('click', '.modal-form-add-btn', function(e){

            e.preventDefault();

            let modal = J(this).attr('data-modal')
            if (J('#'+modal).length) J('#'+modal).fadeIn();
            return;
        });
    },
    Close : () => {

        Jd.off('click', '.modal-close').on('click', '.modal-close', function(){
            J('.modal').fadeOut();
            return;
        });
    }
}
module.exports = modal;
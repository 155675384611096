let priceList = {

    Init : () => {
        priceList.TopNavFix();
    },

    TopNavFix : () => {
        let topNavPosition = J('.pricelist-topnav').offset();
        window.addEventListener("scroll", (event) => {

            if (window.pageYOffset >= topNavPosition.top) {
                J('.pricelist-topnav').addClass('pricelist-topnav-fixed');
            } else {
                J('.pricelist-topnav').removeClass('pricelist-topnav-fixed');
            }
        });
    }
}
module.exports = priceList;
require('jquery-ui-dist/jquery-ui');
let table = {
    Init : () => {
        table.Search();
        table.Actions();

        if (J('.table-list-month').length) {

            J( ".table-list-month" ).sortable({
                items: 'tbody > tr',
                handle: ".table-grip-move",
                connectWith: ".table-list-month",
                update: function( event, ui ) {
                },
                stop: function( event, ui ) {

                    let month = J(ui.item).parent().attr('month');
                    let year = J(ui.item).parent().attr('year');

                    J(ui.item).attr('month', month);
                    J(ui.item).attr('year', year);

                    table.LoopListRowTableMonth();
                }
            }).disableSelection();
        }
    },

    Actions : () => {

        let table_font_size = 14;

        if (J('table').length) {

            if (J('#user_table_font_size').length) {

                let user_table_font_size = J('#user_table_font_size').val();

                if (user_table_font_size.length) {
                    table_font_size = parseInt(user_table_font_size); 
                    J('table').css('font-size',  table_font_size+'px');

                    if (J('table').find('tfoot').find('td').length) {
                        J('table').find('tfoot').find('td').css('font-size', (table_font_size + 4)+'px');
                    }
                }
            }
            if (J('input[name="column_index"]').length) {

                let table_type = J('.table-options-columns-items').attr('table-type');

                J('input[name="column_index"]').each(function(key, item){
                    let index = parseInt(J(item).val());
                    let state = J(item).prop('checked');
                    table.tableColumnsActions(state, index, table_type, 'load');
                });
            }

            /*if (J('.table-options-columns-items').length) {

                let $table = J('table').first();
                
                $table.find('thead').find('th').each(function(index, item) {

                    let text = J(item).text();
                        text = text.trim();

                    if (text.length) {
                    
                        J('.table-options-columns-items').append(`

                            <div column-index="${index}" class="table-options-columns-item">
                                <div class="table-options-columns-item-checkbox"><input type="checkbox" name="column_index" value="${index}" checked /></div>
                                <div class="table-options-columns-item-text">${text}</div>
                            </div>
                        `);
                    }
                });
            }*/
        }

        Jd.off('click', '.table-option-columns').on('click', '.table-option-columns', function(){

            if (J('.table-options-columns-container').length) {
                if (J('.table-options-columns-container').is(':visible')) {
                    J('.table-options-columns-container').hide();
                    J(this).removeClass('table-option-columns-open');
                } else {
                    J('.table-options-columns-container').show();
                    J(this).addClass('table-option-columns-open');
                }
            }
        });

        Jd.off('change', 'input[name="column_index"]').on('change', 'input[name="column_index"]', function(){

            let table_type = J('.table-options-columns-items').attr('table-type');
            let index = parseInt(J(this).val());
            let state = J(this).prop('checked');

            table.tableColumnsActions(state, index, table_type, 'change');

            let data = {
                'user_params' : table_type,
                'value' : []
            }

            if (J('input[name="column_index"]').length) {

                let item_length = J('input[name="column_index"]').length;
                let count_item = 0;

                J('input[name="column_index"]').each(function(key, item){

                    let index = parseInt(J(item).val());
                    let state = J(item).prop('checked');
                    let column_name = J(item).attr('column-name');

                    data.value.push({
                        'column_index' : index,
                        'column_state' : state,
                        'column_name' : column_name
                    });

                    count_item++;

                    if (count_item === item_length) {
                        table.AjaxUserTableParams(data, function(result){
                        });
                    }
                });
            }
        });

        Jd.off('click', '.table-option-font-size-minus').on('click', '.table-option-font-size-minus', function(){
            table_font_size = table_font_size - 1;
            J('table').css('font-size',  table_font_size+'px');

            if (J('table').find('tfoot').find('td').length) {
                J('table').find('tfoot').find('td').css('font-size', (table_font_size + 4)+'px');
            }

            let data = {
                'user_params' : 'table_font_size',
                'value' : table_font_size
            }
            table.AjaxUserTableParams(data, function(result){
            });
        });

        Jd.off('click', '.table-option-font-size-plus').on('click', '.table-option-font-size-plus', function(){
            table_font_size = table_font_size + 1;
            J('table').css('font-size',  table_font_size+'px');

            if (J('table').find('tfoot').find('td').length) {
                J('table').find('tfoot').find('td').css('font-size', (table_font_size + 4)+'px');
            }

            let data = {
                'user_params' : 'table_font_size',
                'value' : table_font_size
            }
            table.AjaxUserTableParams(data, function(result){
            });
        });

        Jd.off('click', '.table-option-font-size-clear').on('click', '.table-option-font-size-clear', function(){
            
            table_font_size = 14;
            J('table').removeAttr('style');

            if (J('table').find('tfoot').find('td').length) {
                J('table').find('tfoot').find('td').removeAttr('style');
            }

            let data = {
                'user_params' : 'table_font_size',
                'value' : ''
            }
            table.AjaxUserTableParams(data, function(result){
            });
        });
    },

    tableColumnsActions : (state, index, table_type, method='load') => {

        console.log(state)

        if (state) {

            J('table').find('thead').find('tr').each(function(key, item){
                J(item).find('th').eq(index).show();
            });

            J('table').find('tbody').find('tr').each(function(key, item){
                J(item).find('td').eq(index).show();
            });

            if (J('table').find('tfoot').length && method === 'change') {

                let index_1_colspan = parseInt(J('table').first().find('td.tfoot-colspan-1').attr('colspan'));

                J('table').find('tfoot').find('tr').each(function(key, item){

                    J(item).find('td[tfoot-index="'+index+'"]').show();

                    if (J('table').find('tfoot').length) {

                        J('table').find('tfoot').find('tr').each(function(key, item){
                            
                            J(item).find('td[tfoot-index="'+index+'"]').show();

                            console.log(table_type)
        
                            if (
                                table_type == 'commands_table_columns' || 
                                table_type == 'quotations_table_columns' || 
                                table_type == 'invoices_table_columns'
                            ) {

                                let new_colspan = index_1_colspan + 1;
        
                                if (index < 8 && index_1_colspan >= 1) {
                                    J(item).find('td.tfoot-colspan-1').attr('colspan', new_colspan);
                                }
                                if (index_1_colspan < 1) {
                                    J(item).find('td.tfoot-colspan-1').hide();
                                }
                                if (index_1_colspan > 0) {
                                    J(item).find('td.tfoot-colspan-1').show();
                                }
                            } else if (
                                table_type == 'mileagecosts_table_columns'
                            ) {

                                let new_colspan = index_1_colspan + 1;

                                console.log(new_colspan)
        
                                if (index < 5 && index_1_colspan >= 1) {
                                    J(item).find('td.tfoot-colspan-1').attr('colspan', new_colspan);
                                }
                                if (index_1_colspan < 1) {
                                    J(item).find('td.tfoot-colspan-1').hide();
                                }
                                if (index_1_colspan > 0) {
                                    J(item).find('td.tfoot-colspan-1').show();
                                }
                            }
                        });
                    }
                });
            }
        } else {

            J('table').find('thead').find('tr').each(function(key, item){
                J(item).find('th').eq(index).hide();
            });

            J('table').find('tbody').find('tr').each(function(key, item){
                J(item).find('td').eq(index).hide();
            });

            if (J('table').find('tfoot').length) {

                let index_1_colspan = parseInt(J('table').first().find('td.tfoot-colspan-1').attr('colspan'));

                J('table').find('tfoot').find('tr').each(function(key, item){

                    J(item).find('td[tfoot-index="'+index+'"]').hide();

                    if (
                        table_type == 'commands_table_columns' || 
                        table_type == 'quotations_table_columns' || 
                        table_type == 'invoices_table_columns'
                    ) {

                        let new_colspan = index_1_colspan - 1;

                        if (index < 8 && index_1_colspan > 1) {
                            J(item).find('td.tfoot-colspan-1').attr('colspan', new_colspan);
                        }
                        if (new_colspan < 1) {
                            J(item).find('td.tfoot-colspan-1').hide();
                        }
                        if (new_colspan > 0) {
                            J(item).find('td.tfoot-colspan-1').show();
                        }
                    } else if (
                        table_type == 'mileagecosts_table_columns'
                    ) {
                        let new_colspan = index_1_colspan - 1;

                        if (index < 5 && index_1_colspan > 1) {
                            J(item).find('td.tfoot-colspan-1').attr('colspan', new_colspan);
                        }
                        if (new_colspan < 1) {
                            J(item).find('td.tfoot-colspan-1').hide();
                        }
                        if (new_colspan > 0) {
                            J(item).find('td.tfoot-colspan-1').show();
                        }

                    }
                });
            }
        }
    },

    AjaxUserTableParams : (data, callback) => {

        let Ajax = J.ajax({
            url : '/admin/users/params/update',
            method: 'POST',
            data: data,
            dataType: 'JSON'
        });

        Ajax.done(function(result){
            return callback(result);
        });

        Ajax.fail(function(error){
            return callback(error);
        });
    },

    LoopListRowTableMonth : () => {

        if (J('.table-list-month-row').length) {

            let rowsList = [];
            let count = 0;
            let rowLength = J('.table-list-month-row').length;

            J('.table-list-month-row').each(function(index, item) {

                let rowType = J(item).attr('row-type');

                let rowId = J(item).attr('row-id');
                let month = J(item).attr('month');
                let year = J(item).attr('year');
                let position = index;

                rowsList.push({
                    row_id : rowId,
                    month : month,
                    year: year,
                    position: position
                });

                count++;

                if (count === rowLength) {
                    table.AjaxPositionMonthRows(rowsList, rowType, function(result){

                        if (result && result.response === 'success') {
                            window.location.reload();
                        }
                    });
                }
            });
        }
    },

    AjaxPositionMonthRows : (data, rowType, callback) => {

        let url = '/admin/commands/ajax/month/row/update/position';
        if (rowType === 'invoice') {
            url = '/admin/invoices/ajax/month/row/update/position';
        }

        let Ajax = J.ajax({
            url : url,
            method: 'POST',
            data: {
                rows_list : data
            },
            dataType: 'JSON'
        });

        Ajax.done(function(result){
            return callback(result);
        });

        Ajax.fail(function(error){
            return callback(error);
        });
    },

    Search : () => {

        /*Jd.off('submit', 'form.table-option-search').on('submit', 'form.table-option-search', function(e){
            e.preventDefault();
            return;
        });*/

        if (J('.table-option-search').length > 0 && J('table > tbody > tr').length > 0) {

            /*Jd.off('keyup', 'input[name="s"]').on('keyup', 'input[name="s"]', function(e){

                let value = (J(this).val()).toUpperCase();
                let $table = J(this).parent().parent().parent().children('table');

                if (value.length > 2) {

                    $table.children('tbody').children('tr').hide();

                    $table.children('tbody').children('tr').each(function(index, item){
                        let trText = (J(item).text()).toUpperCase();
                        if (trText.indexOf(value) != -1) {
                            J(item).show();
                        }
                    });

                } else {
                    $table.children('tbody').children('tr').show();
                }
            });*/
        }
    }
}
module.exports = table;
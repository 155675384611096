let slideshow = {

    Init : () => {

        if (J('.slide-show-item').length) {

            Jd.off('click', '.slide-show-item').on('click', '.slide-show-item', function(){

                let $that       = J(this);
                let imgsrc      = $that.find('img').attr('src');
                let count       = $that.parent().find('.slide-show-item').length;
                let index       = $that.index();
                let rotate = 0;

                let slideshowInterval = null;

                if (J('#slideshow_container').length) {
                    J('#slideshow_container').remove();
                }

                J('body').append(
                    `<div id="slideshow_container" class="slideshow-container" data-index="${index}" current-filepath="${imgsrc}">
                        <div class="slideshow-close"><i class="far fa-times-circle"></i></div>
                        <div class="slideshow-prev"><i class="fas fa-chevron-left"></i></div>
                        <div class="slideshow-img" style="background-image:url(${imgsrc});"></div>
                        <div class="slideshow-next"><i class="fas fa-chevron-right"></i></div>
                        <div class="slideshow-options">
                            <div class="slideshow-rotate-left"><i class="fas fa-undo"></i></div>
                            <div class="slideshow-play"><i class="fas fa-play"></i></div>
                            <div class="slideshow-rotate-right"><i class="fas fa-redo"></i></div>
                        </div>
                    </div>`
                );

                Jd.off('click', '.slideshow-rotate-left').on('click', '.slideshow-rotate-left', function(){
                    let filepath = J('#slideshow_container').attr('current-filepath');
                    J('.slideshow-img').css({
                        'transform' : 'rotate(-'+(rotate+90)+'deg)',
                        'height' : '60%'
                    });

                    rotate = rotate + 90;
                    if (rotate >= 360) {
                        rotate = 0;
                    }
                    slideshow.AjaxSaveImageRotation(filepath, rotate);
                });

                Jd.off('click', '.slideshow-rotate-right').on('click', '.slideshow-rotate-right', function(){
                    let filepath = J('#slideshow_container').attr('current-filepath');
                    J('.slideshow-img').css({
                        'transform' : 'rotate('+(rotate +90)+'deg)',
                        'height' : '60%'
                    });

                    rotate = rotate + 90;
                    if (rotate >= 360) {
                        rotate = 0;
                    }
                    slideshow.AjaxSaveImageRotation(filepath, rotate);
                });

                Jd.off('click', '.slideshow-prev').on('click', '.slideshow-prev', function(){

                    clearInterval(slideshowInterval);
                    slideshowInterval = null;
                    rotate = 0;

                    let lastIndex = parseInt(J(this).parent().attr('data-index')) - 1;

                    if (lastIndex < 0) {
                        lastIndex = J('.slide-show-item').last().index();
                    }

                    if (J('.slide-show-item').eq(lastIndex).length < 1) {
                        lastIndex = 0;
                    }

                    imgsrc = J('.slide-show-item').eq(lastIndex).find('img').attr('src');
                    J('#slideshow_container').attr('current-filepath', imgsrc);

                    J('.slideshow-container').attr('data-index', lastIndex);
                    J('.slideshow-img').attr('style', 'background-image:url('+imgsrc+');');
                    return;
                });
    
                Jd.off('click', '.slideshow-next').on('click', '.slideshow-next', function(){

                    clearInterval(slideshowInterval);
                    slideshowInterval = null;
                    rotate = 0;

                    let nextIndex = parseInt(J(this).parent().attr('data-index')) + 1;

                    if (J('.slide-show-item').eq(nextIndex).length < 1) {
                        nextIndex = 0;
                    }

                    imgsrc = J('.slide-show-item').eq(nextIndex).find('img').attr('src');
                    J('#slideshow_container').attr('current-filepath', imgsrc);

                    J('.slideshow-container').attr('data-index', nextIndex);
                    J('.slideshow-img').attr('style', 'background-image:url('+imgsrc+');');
                    return;
                });

                Jd.off('click', '.slideshow-play').on('click', '.slideshow-play', function(){

                    clearInterval(slideshowInterval);
                    slideshowInterval = null;
                    rotate = 0;

                    let $that = J(this);

                    let slideIndex = parseInt($that.parent().attr('data-index')) + 1;

                    if (J('.slide-show-item').eq(slideIndex).length < 1) {
                        slideIndex = 0;
                    }

                    imgsrc = J('.slide-show-item').eq(slideIndex).find('img').attr('src');
                    J('#slideshow_container').attr('current-filepath', imgsrc);

                    J('.slideshow-container').attr('data-index', slideIndex);
                    J('.slideshow-img').attr('style', 'background-image:url('+imgsrc+');');

                    slideshowInterval = setInterval(function(){

                        rotate = 0;

                        slideIndex = parseInt($that.parent().attr('data-index')) + 1;

                        if (J('.slide-show-item').eq(slideIndex).length < 1) {
                            slideIndex = 0;
                        }

                        imgsrc = J('.slide-show-item').eq(slideIndex).find('img').attr('src');
                        J('#slideshow_container').attr('current-filepath', imgsrc);

                        J('.slideshow-container').attr('data-index', slideIndex);
                        J('.slideshow-img').attr('style', 'background-image:url('+imgsrc+');');

                    }, 1000);
                });

                Jd.off('click', '.slideshow-close').on('click', '.slideshow-close', function(){

                    clearInterval(slideshowInterval);
                    slideshowInterval = null;
                    rotate = 0;
    
                    if (J('#slideshow_container').length) {
                        J('#slideshow_container').remove();
                        return;
                    }
                });
            });

        } else {
            return;
        }
    },

    AjaxSaveImageRotation : (filepath, rotate) => {

        let Ajax = J.ajax({
            url: '/admin/project/media/rotation/save',
            method: 'POST',
            dataType: 'JSON',
            data : {
                filepath : filepath,
                rotate: rotate
            }
        });

        Ajax.done(function(result) {
            console.log(result)
        });

        Ajax.fail(function(error){
            console.log(error)
        });
    }
}
module.exports = slideshow;